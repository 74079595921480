import axiosClient from 'happ-config/axios';

const findCompanyByDniPatient = async dni => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.get(`/scheduling/getCompanyByDniPatient/${dni}`,{headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getSchedules = async company => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.get(`/scheduling/getSchedules/${company}`,{headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const confirmSchedule = async body => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.post('/scheduling/confirmSchedule', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const updateSchedule = async body => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.post('/scheduling/updateSchedule', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const deleteSchedule = async body => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.post('/scheduling/deleteSchedule', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export  {
    findCompanyByDniPatient,
    getSchedules,
    confirmSchedule,
    updateSchedule,
    deleteSchedule
}