
export const INIT_FIND_COMPANY_BY_DNI = 'INIT_FIND_COMPANY_BY_DNI';
export const FIND_COMPANY_BY_DNI = 'FIND_COMPANY_BY_DNI';
export const ERROR_FIND_COMPANY_BY_DNI = 'ERROR_FIND_COMPANY_BY_DNI';

export const INIT_FIND_SCHEDULES = 'INIT_FIND_SCHEDULES';
export const FIND_SCHEDULES = 'FIND_SCHEDULES';
export const ERROR_FIND_SCHEDULES = 'ERROR_FIND_SCHEDULES';

export const INIT_CONFIRM_SCHEDULING = 'INIT_CONFIRM_SCHEDULING';
export const CONFIRM_SCHEDULING = 'CONFIRM_SCHEDULING';
export const ERROR_CONFIRM_SCHEDULING = 'ERROR_CONFIRM_SCHEDULING';

export const INIT_UPDATE_SCHEDULING = 'INIT_UPDATE_SCHEDULING';
export const UPDATE_SCHEDULING = 'UPDATE_SCHEDULING';
export const ERROR_UPDATE_SCHEDULING = 'ERROR_UPDATE_SCHEDULING';

export const INIT_DELETE_SCHEDULING = 'INIT_DELETE_SCHEDULING';
export const DELETE_SCHEDULING = 'DELETE_SCHEDULING';
export const ERROR_DELETE_SCHEDULING = 'ERROR_DELETE_SCHEDULING';

export const ACTIVE_FORM = 'ACTIVE_FORM';