import styled from 'styled-components';

export const Message = styled.div.attrs(props => ({}))`
    background: ${props => props.bgColor ? props.bgColor : '#e81224'};
    border-radius: 8px;
    padding: 10px;
    width: 70%;
    margin: 0 auto;
    & p{
        text-align: center;
        color: #fff;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
`;

export const SelfRegistrationParagraph = styled.div.attrs(props => ({}))`
    & p{
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        color: #000000;
        margin-bottom: 0;
    }
    & .frequency-param{
        font-weight: 400;
        font-size: 16px;
        color: #249CF3;
        text-align: center;
    }
    & .goal-reference{
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        text-align: center;
        margin-bottom: 2px;
    }
    @media(max-width: 700px) {
        & p{
            font-size: 15px;
            text-align: left;
        }
        & .frequency-param{
            font-size: 13px;
            text-align: left;
        }
        & .goal-reference{
            font-size: 13px;
        }
    }
`;

export const SelfRegistrationComplete = styled.p.attrs(props => ({}))`
    background: #198754;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    padding: 5px 0;
    margin: 0;
    line-height: 1;
    margin-top: 12.2px;
    @media(max-width: 700px) {
        font-size: 12px;
    }
`;
