import {
    INIT_FIND_COMPANY_BY_DNI,
    FIND_COMPANY_BY_DNI,
    ERROR_FIND_COMPANY_BY_DNI,
    INIT_FIND_SCHEDULES,
    FIND_SCHEDULES,
    ERROR_FIND_SCHEDULES,
    INIT_CONFIRM_SCHEDULING,
    CONFIRM_SCHEDULING,
    ACTIVE_FORM,
    ERROR_CONFIRM_SCHEDULING,
    INIT_UPDATE_SCHEDULING,
    UPDATE_SCHEDULING,
    ERROR_UPDATE_SCHEDULING,
    INIT_DELETE_SCHEDULING,
    DELETE_SCHEDULING,
    ERROR_DELETE_SCHEDULING
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    errorServer: false,
    errorMsg: null,
    currentPatient: null,
    confirmSchedule: false,
    isActiveSchedule: false,
    activeSchedule : null,
    checkActiveSchedule: false,
    idActiveSchedule: null,
    msgConfirmSchedule: null,
    selectBranches: null,
    errorConfirmSchedule: false,
    msgErrorConfirmSchedule: null,
    schedules: null
}

export default function(state = initialState, action){
    switch(action.type){
        case INIT_FIND_SCHEDULES:
        case INIT_DELETE_SCHEDULING:    
        case INIT_UPDATE_SCHEDULING:    
        case INIT_FIND_COMPANY_BY_DNI:
        case INIT_CONFIRM_SCHEDULING: 
            return {
                ...state,
                loading: true,
                errorServer: false,
                errorMsg: null,
                confirmSchedule: false,
                errorConfirmSchedule: false,
                isActiveSchedule: false,
            }
        case FIND_COMPANY_BY_DNI:
            return {
                ...state,
                loading: false,
                currentPatient: action.payload.existingPatient,
                activeSchedule: action.payload.currentSchedule,
                checkActiveSchedule: action.payload.checkActiveSchedule,
                idActiveSchedule: action.payload.idActiveSchedule,
                selectBranches: action.payload.selectBranches,
                errorMsg: null,
                errorServer: false
            }
        case ERROR_FIND_COMPANY_BY_DNI:
            return {
                ...state,
                loading: false,
                currentPatient: null,
                idActiveSchedule: null,
                selectBranches: null,
                checkActiveSchedule: false,
                errorServer: true,
                activeSchedule: null,
                errorMsg: action.payload
            }
        case FIND_SCHEDULES:
            return {
                ...state,
                loading: false,
                schedules: action.payload.Schedules
            }
        case ERROR_FIND_SCHEDULES:
            return {
                ...state,
                loading: false,
                schedules: null,
                errorServer: true,
                errorMsg: action.payload
            }
        case DELETE_SCHEDULING:    
        case UPDATE_SCHEDULING:    
        case CONFIRM_SCHEDULING:
            return {
                ...state,
                loading: false,
                errorConfirmSchedule: false,
                msgErrorConfirmSchedule: null,
                activeSchedule: null,
                checkActiveSchedule: false,
                idActiveSchedule: null,
                confirmSchedule: true,
                msgConfirmSchedule: action.payload.msg,
                schedules: null,
            }
        case ERROR_DELETE_SCHEDULING:    
        case ERROR_UPDATE_SCHEDULING:              
        case ERROR_CONFIRM_SCHEDULING:
            return {
                ...state,
                loading: false,
                confirmSchedule: false,
                msgConfirmSchedule: null,
                activeSchedule: null,
                idActiveSchedule: null,
                checkActiveSchedule: false,
                errorConfirmSchedule: true,
                msgErrorConfirmSchedule: action.payload,
            }
        case ACTIVE_FORM:
            return {
                ...state,
                checkActiveSchedule: false
            }                  
        default:
            return state;
    }
}


