import {
    INIT_FIND_COMPANY_BY_DNI,
    FIND_COMPANY_BY_DNI,
    ERROR_FIND_COMPANY_BY_DNI,
    INIT_FIND_SCHEDULES,
    FIND_SCHEDULES,
    ERROR_FIND_SCHEDULES,
    INIT_CONFIRM_SCHEDULING,
    CONFIRM_SCHEDULING,
    ACTIVE_FORM,
    ERROR_CONFIRM_SCHEDULING,
    INIT_UPDATE_SCHEDULING,
    UPDATE_SCHEDULING,
    ERROR_UPDATE_SCHEDULING,
    INIT_DELETE_SCHEDULING,
    DELETE_SCHEDULING,
    ERROR_DELETE_SCHEDULING
} from 'happ-redux/happ-types';
import { findCompanyByDniPatient, getSchedules, confirmSchedule, updateSchedule, deleteSchedule } from 'happ-redux/happ-services/schedule/schedule-services';

export function findCompanyByDniPatientAction(dni, navigate) {
    return async (dispatch) => {
        dispatch(initFindCompanyByDni());
        try {
            const response = await findCompanyByDniPatient(dni);
            if (response.status === 200) {
                dispatch(findCompanyByDni(response.data));
            } else {
                dispatch(errorFindCompanyByDni(response.data.msg));
            }
        } catch (error) {
            dispatch(errorFindCompanyByDni(error.data.msg));
        }
    }
}

export function getSchedulesActions(company, navigate) {
    return async (dispatch) => {
        dispatch(initFindSchedules());
        try {
            const response = await getSchedules(company);
            if (response.status === 200) {
                dispatch(findSchedules(response.data));
            } else {
                dispatch(errorFindSchedules(response.data.msg));
            }
        } catch (error) {
            dispatch(errorFindSchedules(error.data.msg));
        }
    }

}

export function confirmScheduleAction(schedule, navigate) {
    return async (dispatch) => {
        dispatch(initConfirmSchedule());
        try {
            const response = await confirmSchedule(schedule);
            if (response.status === 200) {
                dispatch(sendSchedule(response.data));
            } else {
                dispatch(errorConfirmSchedule(response.data.msg));
            }
        } catch (error) {
            dispatch(errorConfirmSchedule(error.data.msg));
        }
    }
}

export function updateScheduleAction(schedule, activeSchedule, navigate) {
    return async (dispatch) => {
        dispatch(initUpdateSchedule());
        try {
            schedule.currentSchedule = activeSchedule;
            const response = await updateSchedule(schedule);
            if ( response.status === 200 ) {
                dispatch(updateScheduleType(response.data));
            } else {
                dispatch(errorUpdateSchedule(response.data.msg));
            }
        } catch (error) {
            dispatch(errorUpdateSchedule(error.data.msg));
        }
    }
}

export function deleteScheduleAction(currentSchedule) {
    return async (dispatch) => {
        dispatch(initDeleteSchedule());
        try {
            const response = await deleteSchedule(currentSchedule);
            if ( response.status === 200 ) {
                dispatch(deleteScheduleType(response.data));
            } else {
                dispatch(errorDeleteSchedule(response.data.msg));
            }
        } catch (error) {
            dispatch(errorDeleteSchedule(error.data.msg));
        }
    }
}

export function activeFormAction() {
    return (dispatch) => {
        dispatch(activeForm());
    }
}

const initFindCompanyByDni = () => ({
    type: INIT_FIND_COMPANY_BY_DNI
});

const findCompanyByDni = response => ({
    type: FIND_COMPANY_BY_DNI,
    payload: response
});

const errorFindCompanyByDni = error => ({
    type: ERROR_FIND_COMPANY_BY_DNI,
    payload: error
});

const initFindSchedules = () => ({
    type: INIT_FIND_SCHEDULES
});

const findSchedules = response => ({
    type: FIND_SCHEDULES,
    payload: response
});

const errorFindSchedules = error => ({
    type: ERROR_FIND_SCHEDULES,
    payload: error
});

const initConfirmSchedule = () => ({
    type: INIT_CONFIRM_SCHEDULING
});

const sendSchedule = response => ({
    type: CONFIRM_SCHEDULING,
    payload: response
});

const errorConfirmSchedule = error => ({
    type: ERROR_CONFIRM_SCHEDULING,
    payload: error
});

const activeForm = () => ({
    type: ACTIVE_FORM
});

const initUpdateSchedule = () => ({
    type: INIT_UPDATE_SCHEDULING
});

const updateScheduleType = response => ({
    type: UPDATE_SCHEDULING,
    payload: response
});

const errorUpdateSchedule = error => ({
    type: ERROR_UPDATE_SCHEDULING,
    payload: error
});

const initDeleteSchedule = () => ({
    type: INIT_DELETE_SCHEDULING
});

const deleteScheduleType = response => ({
    type: DELETE_SCHEDULING,
    payload: response
});

const errorDeleteSchedule = error => ({
    type: ERROR_DELETE_SCHEDULING,
    payload: error
});

