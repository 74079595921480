import React from 'react';
import {   
    Routes,
    Route,
    Navigate } from 'react-router-dom';
import {Row} from "react-bootstrap";

//Views
import Scheduling from "happ-views/Scheduling/Scheduling";
import SchedulingTele from 'happ-views/SchedulingTele/SchedulingTele';
//components

//actions

const RoutesMap = () => {

    const RoutesWithoutNavBar = ({ children }) => {
        return (
        
            children
        );
    }
    return ( 
        <Routes>
            <Route 
                path="/agendamiento-emp" 
                element={
                    <RoutesWithoutNavBar>
                        <Scheduling />
                    </RoutesWithoutNavBar>
                } 
            />
            <Route 
                path="/agendamiento-telemedicina" 
                element={
                    <RoutesWithoutNavBar>
                        <SchedulingTele />
                    </RoutesWithoutNavBar>
                } 
            />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
    );
}
 
export default RoutesMap;