import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Form, Button} from 'react-bootstrap';
import { formatRut, checkRut } from "react-rut-formatter";
import moment from 'moment';
import 'moment/min/locales';
import SweetAlert2 from 'react-sweetalert2';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import DniUser from 'happ-components/DniUser/DniUser';
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
//custom-hooks
//actions
import { 
    confirmScheduleAction, 
    activeFormAction, 
    updateScheduleAction, 
    getSchedulesActions, 
    findCompanyByDniPatientAction, 
    deleteScheduleAction } from 'happ-redux/happ-actions/schedule/scheduleActions';
import logo from 'assets/imgs/logo-happ-heart.svg';

const Scheduling = () => {
    moment.locale('es');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [localHours, setLocalHours] = useState([]);
    const [errorDni, setErrorDni] = useState(false);
    const [errorHours, setErrorHours] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);
    const [form, setForm] = useState({
        operativeDay: '',
        currentOperative: '',
        dni: '',
        patient: '',
        currentBranch: null,
        schedulesByBranch: null,
        currentSchedule: null,
        operative: '',
        phone: '',
        check: false,
        hour:{}
    });
    
    const {operativeDay, dni, phone, hour, patient, operative, currentOperative, currentBranch,schedulesByBranch, check} = form;
    const {
        errorServer, 
        errorMsg, 
        currentPatient, 
        schedules, 
        confirmSchedule, 
        errorConfirmSchedule, 
        activeSchedule,
        checkActiveSchedule,
        selectBranches 
    } = useSelector(state => state.schedule);
    const [confirmScheduleMsg, setConfirmScheduleMsg] = useState({});
    const [errorconfirmScheduleMsg, setErrorconfirmScheduleMsg] = useState({
        show: true,
        text: 'Hubo en error, vuelva a inténtelo en unos minutos',
        type: 'success',
        icon: 'error',
    });
    useEffect(() => {
        if (currentPatient !== null) {
            const company = currentPatient?.company._id;
            if (String(currentPatient.phone) !== '987654321') {
                form.phone = currentPatient.phone
            }
            dispatch(getSchedulesActions(company, navigate));
        }
    }, [currentPatient]);

    useEffect(() => {
        if (confirmSchedule) {
            form.operativeDay = '';
            form.dni = '';
            form.patient = '';
            form.operative = '';
            form.currentBranch = null;
            form.schedulesByBranch = null;
            form.currentOperative = '';
            form.phone = '';
            form.hour = {};
            form.check = false;
        }
        if (errorConfirmSchedule) {
            form.hour = {};
        }
    }, [confirmSchedule, errorConfirmSchedule])
    
    const handlerChangeLocalDni = e => {
        setErrorDni(false);
        const dni = e.target.value;
        const formatted = formatRut(dni);
        setForm({
            ...form,
            dni: formatted
        });
    }

    const handlerChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const searchDni = e => {
        e.preventDefault();
        if (!checkRut(dni) || dni === '') {
            setErrorDni(true);
            return;
        }
        setErrorDni(false);
        dispatch(findCompanyByDniPatientAction(dni, navigate));
    }

    const showHoursByDay = e => {
        const operative = schedules.filter(item => item._id === e.target.value);
        setForm({
            ...form,
            currentOperative: e.target.value,
            hour: {},
            operativeDay: operative[0] ? moment.utc(operative[0].operativeDay).format('YYYY-MM-DD') : '',
            company: operative[0].company,
            operative: operative[0].operative,
        })
        setLocalHours(operative);
    }
    
    const selectHour = e => {
        setForm({
            ...form,
            hour: {
                _id: e.target.value.split('/', 1)[0],
                text: e.target.value.split('/', 2)[1]
            },
            patient: currentPatient._id,
        });

    }

    const selectCurrentBranch = e => {
        setLocalHours([]);
        form.schedulesByBranch = null;
        const currentSchedulesByBranch = schedules.filter(item => {
            if (item.BranchOfficeId === e.target.value) {
                return item;
            }
        })
        setForm({
            ...form,
            currentBranch: e.target.value,
            schedulesByBranch: currentSchedulesByBranch
        });
    }
    
    const comfirm = e => {
        e.preventDefault();
        if (Object.keys(hour).length === 0 || phone === '' || String(phone).length !== 9 || check === false) {
            if (Object.keys(hour).length === 0) {
                setErrorHours(true);
            }
            if (String(phone) === '' || String(phone).length !== 9) {
                setErrorPhone(true);
            }
            if ( !check ) {
                setErrorCheck(true);
            }
            return;
        }
        setErrorPhone(false); 
        setErrorCheck(false);
        setErrorHours(false);
        if (activeSchedule === null){
            dispatch(confirmScheduleAction(form, navigate));
        } else {
            dispatch(updateScheduleAction(form, activeSchedule, navigate));
        }
        form.operativeDay = '';
        form.currentOperative = '';
        form.dni = '';
        form.currentBranch = null;
        form.schedulesByBranch = null;
        form.patient = '';
        form.currentSchedule = null;
        form.operative = '';
        form.phone = '';
        form.check = false;
        form.hour ={};
        setLocalHours([]);
        setConfirmScheduleMsg({
            show: true,
            text:  'Su agendamiento se ha confirmado con éxito, en unos minutos le llegará una notificación con el detalle de la cita vía email',
            type: 'success',
            icon: 'success',
        });
    }

    const activeForm = e => {
        e.preventDefault();
        dispatch(activeFormAction());
    }
    
    const deleteSchedule = e => {
        e.preventDefault();
        dispatch(deleteScheduleAction(activeSchedule))
        form.operativeDay = '';
        form.currentOperative = '';
        form.dni = '';
        form.patient = '';
        form.currentSchedule = null;
        form.currentBranch = null;
        form.schedulesByBranch = null;
        form.operative = '';
        form.phone = '';
        form.check = false;
        form.hour ={};
        setLocalHours([]);
        setConfirmScheduleMsg({
            show: true,
            text:  'Su agendamiento fue eliminado con éxito',
            type: 'success',
            icon: 'success',
        });
    }
    
    return ( 
        <Row className="mt-content">
            <HappContainer>
                {confirmSchedule ? (
                    <SweetAlert2 
                        {...confirmScheduleMsg}
                    />
                ) : null}
                {errorConfirmSchedule ? (
                    <SweetAlert2 
                        {...errorconfirmScheduleMsg}
                    />
                ) : null}
                <div className="mb-5 mt-md-4 mt-3">
                    <div className="content-logo">
                        <img src={logo} alt="description"/>
                    </div>
                </div>
                <Col sm={12} lg={12} xs={10} md={12} className="mb-3 mt-2 col-dni-mobile">
                    <DniUser 
                        dni={dni}
                        errorDni={errorDni}
                        errorServer={errorServer}
                        errorMsg={errorMsg}
                        handlerChangeLocalDni={handlerChangeLocalDni}
                        searchDni={searchDni}
                    />
                </Col>
                <Col sm={12} lg={12} md={12} className="mb-3 mt-2">
                    {checkActiveSchedule ? (
                        <>
                            <Message
                                bgColor="#044064"
                            >
                                <>
                                    <p>Tienes una cita</p>
                                    <p>Día: {moment.utc(activeSchedule.operativeDay).format("dddd, D MMMM YYYY")} </p>
                                    <p>Hora: {activeSchedule.hour} Hrs</p>
                                </>
                            </Message>
                            <Col sm={5} lg={5} xs={10} md={5} className='align-items-center text-center justify-content-center mt-3 mb-3 col-btn-actions'>
                                <Button
                                    className="info-bg-color btn-action" 
                                    type="button"
                                    onClick={activeForm}
                                >Cambiar Cita</Button>
                                <Button
                                    className="danger-bg-color btn-action" 
                                    type="button"
                                    onClick={deleteSchedule}
                                >Eliminar Cita</Button>
                            </Col>
                        </>
                    ) : (
                        schedules ? (
                            <>
                                <Form>
                                    <p className='schedule-title'>Teléfono celular</p>
                                    <Col sm={5} lg={5} md={5} className='pt-2 col-form col-phone'>
                                        <Col sm={2} lg={2} xs={2} md={2}>
                                            <Form.Group>
                                                <Form.Control 
                                                    type="text" 
                                                    name="pre-phone"
                                                    value="+56"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={8} lg={8} xs={8} md={8}>
                                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                                <Form.Control 
                                                    type="text" 
                                                    name="phone"
                                                    placeholder="9 dígitos"
                                                    value={phone ? phone : ''}
                                                    onChange={handlerChange}
                                                />
                                            </Form.Group>
                                        {errorPhone ? (
                                            <Message>
                                                <p>El formato del teléfono es de 9 digitos</p>
                                            </Message>
                                        ) : null}
                                        </Col>
                                    </Col>
                                    <Col sm={5} lg={5} xs={10} md={5} className="mb-3 mt-3 col-form">
                                        <Form.Group  controlId="healthForecast">
                                            <p className='schedule-title'>Selecciona Sucursal ó área de trabajo</p>
                                            <Form.Select 
                                                name="currentBranch"
                                                onChange={selectCurrentBranch} 
                                                value={currentBranch ? currentBranch : ''}
                                            >
                                                <option value="">Sucursal o área de trabajo</option>
                                                {selectBranches.map(item => (
                                                        <option 
                                                            key={item._id} 
                                                            value={item._id}
                                                        >{item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {schedulesByBranch ? (
                                        <>
                                            <Col sm={5} lg={5} xs={10} md={5} className="mb-3 mt-3 col-form">
                                                <Form.Group  controlId="healthForecast">
                                                    <p className='schedule-title'>Seleccione un día</p>
                                                    <Form.Select 
                                                        name="currentOperative"
                                                        onChange={showHoursByDay} 
                                                        value={currentOperative ? currentOperative : ''}
                                                    >
                                                        <option value="">Seleccione un Día</option>
                                                        {schedulesByBranch.map(item => (
                                                                <option 
                                                                    key={item._id} 
                                                                    value={item._id}
                                                                >{moment.utc(item.operativeDay).format('dddd, D MMMM YYYY')}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={5} lg={5} xs={10} md={5} className="mt-2 col-form">
                                                {localHours ? (
                                                    localHours.map(item => {
                                                        const {hours, operativeDay, _id} = item;
                                                        return (
                                                            <div key={_id}>
                                                                <p className='operative-day'>{moment.utc(operativeDay).format('MMMM Do YYYY')}</p>
                                                                <select 
                                                                    className="form-select mb-3" 
                                                                    size="5" 
                                                                    aria-label="size 5 select example" 
                                                                    onChange={selectHour}
                                                                >
                                                                    <option className='init-opt' selected>Seleccione una hora</option>
                                                                    {hours.map(hourItem => (
                                                                        <option 
                                                                            className={`opt ${!hourItem.status ? 'blocked' : ''}`} 
                                                                            key={hourItem._id} 
                                                                            value={`${hourItem._id}/${hourItem.hour}`}
                                                                            disabled={hourItem.status ? false : true}
                                                                        >
                                                                            {hourItem.hour}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )
                                                    })
                                                ) : null}
                                                {errorHours ? (
                                                    <Message>
                                                        <p>Debe seleccionar una hora</p>
                                                    </Message>
                                                ) : null}
                                            </Col>
                                            <Col sm={4} lg={4} md={4} className="mb-3 mt-5 col-form">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        placeholder="11111111-1" 
                                                        name="check" 
                                                        onChange={handlerChange}
                                                        value={check ? check : false}
                                                        label={
                                                            <p>
                                                                Debe aceptar &nbsp;
                                                                <a 
                                                                    className="" 
                                                                    href="https://patientexamrepository.s3.sa-east-1.amazonaws.com/media/terminosycondiciones/Consentimiento+informado.pdf"
                                                                    target="_blank"
                                                                >
                                                                    Consentimiento informado
                                                                </a> 
                                                            </p>
                                                        }
                                                    />
                                                </Form.Group>
                                                {errorCheck ? (
                                                    <Message>
                                                        <p>Debe aceptar consentimiento informado</p>
                                                    </Message>
                                                ) : null}
                                            </Col>
                                        </>
                                    ): null}
                                    <Col sm={12} lg={12} md={12} className="mb-3 mt-5 col-btn-action">
                                        <Button 
                                            type="button"
                                            className="btn primary-bg-color text-white"
                                            onClick={comfirm}
                                            disabled={confirmSchedule ? true : false}
                                        >
                                            Agendar cita
                                        </Button>
                                    </Col>
                                </Form>
                            </>
                        ) : null
                    )}
                </Col>    
            </HappContainer>    
        </Row>
    );
}
 
export default Scheduling;