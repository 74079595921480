import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Form, Button} from 'react-bootstrap';
//components
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';

const DniUser = ({...rest}) => {
    const {
        dni,
        errorDni,
        errorServer,
        errorMsg,
        handlerChangeLocalDni,
        searchDni
    } = rest;

    return (
        <>
        <Row className="align-items-center justify-content-center mt-3 mb-3">
            <Col sm={3} className="my-1 dni-row">
                <Form.Label 
                    htmlFor="dni" 
                >
                    Ingresa tu Rut
                </Form.Label>
                <Form.Control 
                    id="dni" 
                    name="dni"
                    value={dni}
                    onChange={handlerChangeLocalDni}
                    placeholder="11111111-1" 
                />
                <small>Para agendar, cambiar ó eliminar tu cita.</small>
            </Col>
            <Col xs="auto" className="">
                <Button
                    className="primary-bg-color btn-dni" 
                    type="button"
                    onClick={searchDni}
                >Continuar</Button>
            </Col>
        </Row>
        <Row className="align-items-center justify-content-center mt-3 mb-3">
            <Col sm={5} lg={5} md={5} className="align-items-center">
                {errorDni ? (
                    <Message>
                        <p>El RUT ingresado no es valido</p>
                    </Message>
                ) : null}
                {errorServer ? (
                    <Message>
                        <p>{errorMsg}</p>
                    </Message>
                ) : null}
            </Col>
        </Row>
        </>
    );
}
 
export default DniUser;